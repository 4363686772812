import jsxTemplate from './HeaderTemplate.jsx';
import vx, { ajax } from '@vx/framework';
import * as styles from './Header.module.css';
import NavigationController from '../navigation/NavigationController';

const salesUnitPath = 'light/login/salesunit';

export default function HeaderController() {
  this.jsxTemplate = jsxTemplate;
  const self = this;
  self.parameters = {
    light: 'casurl',
  };
  self.canRemoveEvents = false;

  async function getUserDetails() {
    const user = await self.app.to(self.app.context.currentEmployee.getMe());

    self.userData = {
      firstName: user.firstName,
      surname: user.surname,
      selectedUnit: user.salesUnits.find((el) => el.statisticalUnitNumber === user.lastUnitNumber),
      id: user.employeeId,
      availableUnits: user.salesUnits,
    };
  }

  function rerender() {
    self.render();

    vx.load(NavigationController, {
      areaId: self.menuId,
      abortable: false,
    });
  }

  self.onLogout = () => {
    self.app.clearSession();
    const casLogoutUrl = `${self.getParameter.light.casurl}/logout?service=${encodeURIComponent(
      self.app.getLogoutPageUrl(),
    )}`;
    window.location = casLogoutUrl;
  };

  self.toggleEmployeeMenu = (e) => {
    document.querySelector('#' + styles.overlay).classList.toggle('hidden');
    document.querySelector('#' + styles.user).classList.toggle('current');
  }

  self.closeEmployeeMenu = (e) => {
    document.querySelector('#' + styles.overlay).classList.add('hidden');
    document.querySelector('#' + styles.user).classList.remove('current');
  }

  self.changeUserUnit = async (event) => {
    const newUnitId = event.target.getAttribute('vx-unit-number');
    if (!newUnitId) { return; }
    const newToken = (
      await self.app.to(
        ajax.post(salesUnitPath, null, {
          statisticalUnitNumber: newUnitId,
        }),
      )
    ).token;
    sessionStorage.removeItem('platinEncodedToken');
    sessionStorage.removeItem('user');
    sessionStorage.setItem('platinToken', newToken);

    window.location.reload();
  }

  self.init = async function init() {
    self.menuId = 'menu';

    await getUserDetails();
    rerender();
  };
}
