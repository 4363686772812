import jsxTemplate from './ErrorTemplate.jsx';
import template from './AlertTemplate.vtl';
export default function ErrorController() {
  this.jsxTemplate = jsxTemplate;
  this.template = template;
  const self = this;

  self.init = function init({ err, popup: { close } }) {
    err.date = err.date || new Date();
    self.err = err;
    self.render();
    self.close = close;
    console.error(err);
  };
}
