import jsxTemplate from './ErrorTemplate.jsx';
import template from './AlertTemplate.vtl';
/* eslint-disable */
import vx, { $I, dom, validate } from '@vx/framework';

export default function () {
  const self = this;
  const DEFAULT_MESSAGE = '<h2></h2> <div>Trwa wczytywanie. Proszę czekać...</div>';

  let clickHandler;

  self.setEvents = function () {
    clickHandler = self.addEvent(self.$wrap, 'click', self.clickDispatcher, self);
  };

  self.init = function (params) {
    validate.notNull(params.controller, 'Należy podać ścieżkę do kontrolera, który ma zostać załadowany w popup');
    validate.notNull(params.areaId, 'Należy podać id obszaru z popup');

    self.params = params;
    self.params.header = self.params.header || '';
    self.closeCallbackFn = self.params.sendMessageFn;
    self.params.buttons = self.params.buttons || [];
    self.params.message = self.params.message || DEFAULT_MESSAGE;

    self.areaId = params.areaId;
    self.params = params;

    self.wrapId = `${self.areaId}_Container`;
    self.overlayId = `${self.areaId}_Overlay`;
    self.popupId = `${self.areaId}popup`;

    // wrapper - contains all the content
    self.$wrap = document.createElement('div');
    self.$wrap.id = self.wrapId;
    self.$wrap.classList.add('modalPopupShow');
    self.$wrap.style.width = self.params.width || '600px';

    // overlay - for ui (gray background)
    self.$popupOverlay = document.createElement('div');
    self.$popupOverlay.id = self.overlayId;
    self.$popupOverlay.classList.add('modalPopupOverlay');

    const $header = document.createElement('div');
    $header.classList.add('popup_header');
    $header.innerHTML = `${self.params.header}<div class="popup_close" vx-popup-close="true" id=${self.areaId}_Close></div>`;

    const contentId = self.areaId + '-message';
    const $content = document.createElement('div');
    $content.classList.add('popup_message');
    $content.id = contentId;

    self.$wrap.appendChild($header);
    self.$wrap.appendChild($content);

    self.$template = document.getElementById('template');
    self.$template.appendChild(self.$wrap);
    self.$template.appendChild(self.$popupOverlay);
    self.adjustSize();
    self.setEvents();

    const cparams = self.params.controllerParams || {};
    cparams.popup = self;
    cparams.areaId = contentId;
    cparams.closeFn = self.close;
    vx.load(self.params.controller, cparams);
  };

  self.adjustSize = function () {
    const innerHeight = self.$wrap.offsetHeight;
    const topx = document.documentElement.scrollTop;
    const halfScreenHeight = dom.windowHeight() / 2;
    const halfPopupHeight = innerHeight / 2;
    let delta = halfScreenHeight - halfPopupHeight;
    if (delta < 0) delta = 0;
    self.$wrap.style.top = parseInt(topx) + parseInt(delta) + 'px';
  };

  self.clickDispatcher = function (event) {
    const $area = $I(self.areaId);
    let marker = self.getAttributeFromEvent($area, event, 'vx-popup-close');
    if (marker) {
      if (self.params.onPopupCloseCallback) self.params.onPopupCloseCallback();
      self.close();
      return;
    }
    marker = self.getAttributeFromEvent($area, event, 'vx-popup-button');
    if (marker) {
      const b = self.params.buttons[marker];
      if (b && b.callback) b.callback(o);
    }
  };

  self.getAttributeFromEvent = function ($area, e, name) {
    let target;
    let v = null;
    target = e.target || e.srcElement;
    while (v == null) {
      v = target.getAttribute(name);
      if (v) return v;
      target = target.parentElement;
      if (!target || target === $area) return null;
    }
    return null;
  };

  self.close = (data) => {
    self.$wrap.removeEventListener('click', clickHandler);
    self.$template.removeChild(self.$wrap);
    self.$template.removeChild(self.$popupOverlay);
    if (self.closeCallbackFn) {
      self.closeCallbackFn(data);
    }
  };
}
