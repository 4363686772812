let modules = {};

export default function onHashChangeHandler() {
  let hash = window.location.hash;
  let splitted = hash.split('/');
  let moduleName = splitted[0].substring(1);
  const parentPath = window.location.href.replace(/\/host.*/, '');

  if (modules[moduleName]) return;

  modules[moduleName] = true;

  const container = document.head || document.body;
  let timestamp = Date.now();
  const e = document.createElement('script');
  e.type = 'text/javascript';
  e.async = true;
  e.src = `${parentPath}/${moduleName}/module.js?v=${timestamp}`;
  container.appendChild(e);

  const linkEl = document.createElement('link');
  linkEl.rel = 'stylesheet';
  linkEl.type = 'text/css';
  linkEl.href = `${parentPath}/${moduleName}/main.css?v=${timestamp}`;
  container.appendChild(linkEl);
}
