import * as styles from './ErrorTemplate.module.css';
import { Button } from '@vx/components/buttons';

const ErrorTemplate = ({ o }) => (
  <div class={styles.errorContainer}>
    <div class={styles.xIcon}></div>
    <h2>Wystąpił błąd techniczny aplikacji.</h2>
    <p>Kod błędu: {o.err.status}</p>
    <Button type="primary" label="Zamknij okno" onClick={() => o.close()} />
  </div>
);

export default ErrorTemplate;
