//Plik zawiera obiekty/funkcje eksportowane przez hosta.
//Można je zaimportować przez
//import host from '@platin-light/host
//Wówczas kod hosta jest wbudowywany w paczkę z modułem
//ALE
//W plikach index-[dev][prod].js Są one również przypisywane do zmiennej window.host
//Dzięki temu w module korzystającym z funkcji hosta można hosta zdefiniować
//jako external i korzystać bezpośrenio z funkcji hosta dostępnych w runtime
//Dzięki temu domyślnie możemy korzystać z zależności runtime, ale w przypadku gdy
//jakaś zmiana w host popsuje jakiś moduł, można tylko w tym module dać zależność
//bez external i wbudować wcześniejszą wersję hosta w moduł
//Dość często powinno to zadziałać ;-)

import extensions from './platin/extension-points';

import formatters from './vx-setup/vx-formatters.js';
import tags from './vx-setup/tags';
import Tag from './vx-setup/tags/Tag';
import * as app from './platin';

const contentArea = 'main-content';
const dontLoadDefaultHash = true;
const vxParams = { formatters, tags, app, extensions, contentArea, dontLoadDefaultHash };

const host = {
  vxParams,
  Tag,
  app,
};

export default host;
