import * as Permissions from '../../permissions';

export default function ({ userHasPermission, showExtendedContext }) {
  let menu = [
    {
      label: 'Start',
      href: 'host/#welcome/main',
    },
    {
      label: 'Wnioski',
      href: 'host/#welcome/proposals',
    },
    {
      label: 'Oferty',
      href: 'host/#welcome/offers',
    },
    // {
    //   label: 'Informacje',
    //   href: 'host/#welcome/information-service',
    //   available: userHasPermission(Permissions.LIGHT_INFORMATION_MENU),
    //   children: [
    //     {
    //       label: 'Serwis informacyjny',
    //       href: 'host/#welcome/information-service',
    //     },
    //   ],
    // },
    {
      label: 'Zarządzanie',
      href: 'host/#welcome/user-preferences',
      available: userHasPermission(Permissions.LIGHT_MANAGEMENT_MENU),
      children: [
        {
          label: 'Ustawienia indywidualne',
          href: 'host/#welcome/user-preferences',
        },
      ],
    },
    {
      label: 'Szkolenia',
      href: 'host/#welcome/elssa',
      available: userHasPermission(Permissions.LIGHT_ELSA),
    },
    {
      label: 'Przepisy i formularze',
      href: 'host/#welcome/fo-form?type=Dokumenty',
      available: userHasPermission(Permissions.LIGHT_ELSA),
    },
    {
      label: 'Zmiana hasła',
      href: 'host/#welcome/change-password',
    },
  ];

  // Ukrywa "Wnioski" oraz "Oferty" dla pracowników bez uprawnień "showExtendedContext"
  if (!showExtendedContext) {
    menu = menu.filter(item => item.label !== "Wnioski" && item.label !== "Oferty")
  }
  return menu
}
