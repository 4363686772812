import jsxTemplate from './FooterTemplate.jsx';
import './Footer.module.css';

export default function FooterController() {
  this.jsxTemplate = jsxTemplate;
  const self = this;

  self.canRemoveEvents = false;

  self.init = function () {
    self.render();
  };
}
