import jsxTemplate from './ErrorTemplate.jsx';
import template from './AlertTemplate.vtl';

export default function() {
  const self = this;
  self.template = template;

  self.onOk = function() {
    if (self.okCallback) self.okCallback();
    self.popup.close();
  };

  self.init = function(params) {
    console.log(params);
    self.title = params.title;
    self.message = params.message;
    self.okCallback = params.ok;
    self.okLabel = params.okLabel || 'Ok';
    self.popup = params.popup;
    self.icon = params.icon;
    self.render();
    self.popup.adjustSize();
  };

  self.events = {
    'okButton click': self.onOk,
  };
}
