
  import { modelFormatters } from '@vx/framework';

  const template = function(o, tags){
    var _e= o.encode;
    

    let _s='<a class="button button-gradient icon-exclamation-circle" id="messages-container-button" title="Komunikaty" vx-action="iconClick"> <span class="bubble bubble-count" id="messages-container-counter">0</span> </a> <span class="dropdown"> <span class="dropdown-content"> <span class="dropdown-items" id="messages-container-container" vx-action="dispatchItemClick"> </span> <span class="close-messages" id="messages-container-close-button" vx-action="closeContainer"></span> <a class="button button-action icon-trash-o dismiss-messages" id="messages-container-delete-all-button" title="Usuń" vx-action="deleteAllItems"></a> </span> </span>';
    return _s;
  }; 
  template._FILEPATH = 'src/js/platin/controllers/message/MessagesTemplate.vtl';
  export default template;
  