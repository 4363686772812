import jsxTemplate from './NavigationTemplate.jsx';
import rootNavigationDefinition from './root-navigation-definition';
import * as Permissions from '../../permissions';

export default function NavigationController() {
  this.jsxTemplate = jsxTemplate;
  const self = this;
  self.parameters = {
    light: 'casurl',
  };
  self.canRemoveEvents = false;
  self.permissions = {
    light: `${Permissions.LIGHT_ELSA},${Permissions.LIGHT_INFORMATION_MENU},${Permissions.LIGHT_REPORTS_MENU},${Permissions.LIGHT_MANAGEMENT_MENU},${Permissions.LIGHT_ESO_EMPLOYEES},${Permissions.LIGHT_COOPERATING_ESOS}`,
  };

  let isSomeElActive = false;

  function getUrlHash(url) {
    const hashIndex = [...url].findIndex((el) => el === '#');
    if (hashIndex > -1) {
      return url.substring(hashIndex, url.length);
    }
    return '';
  }

  function findActiveElement(menuData) {
    if (Array.isArray(menuData)) {
      menuData.forEach((el) => findActiveElement(el));
      return menuData.some((el) => el.active);
    }

    const currentHash = window.location.hash;
    let isActive;

    if (!Object.prototype.hasOwnProperty.call(menuData, 'available')) {
      menuData.available = true;
    }
    if (menuData.children) {
      isActive = findActiveElement(menuData.children);
      menuData.active = isActive;
      if (isActive) isSomeElActive = true;
    } else {
      // Sprawdzamy, czy url w nagiwacji odpowiada obecnemu URL (z pominięciem parametrów query)
      const isHrefActive = currentHash.includes(getUrlHash(menuData.href.split('?')[0]));
      const isHighlightActive =
        menuData?.highlightOn?.some((url) => currentHash.includes(getUrlHash(url.split('?')[0]))) || false;
      isActive = isHrefActive || isHighlightActive;

      menuData.active = isActive;
      if (isActive) isSomeElActive = true;
    }
    return false;
  }

  function onLogoutButtonClick() {
    self.app.clearSession();
    window.location = `${self.getParameter.light.casurl}/logout?service=${encodeURIComponent(
      self.app.getLogoutPageUrl(),
    )}`;
  }

  function userHasPermission(permission) {
    return self.hasPermissionTo.light[permission];
  }

  self.init = async function init() {
    const { showExtendedContext } = await self.app.context.currentEmployee.getMe();
    self.menuItems = rootNavigationDefinition({ userHasPermission, showExtendedContext });
    window.addEventListener('hashchange', () => {
      isSomeElActive = false;
      findActiveElement(self.menuItems);
      if (!isSomeElActive) {
        self.menuItems[0].active = true;
      }
      self.sendMessage('submenu2', 'refreshSecondaryNav', self.menuItems.find((el) => el.active).children || []);
      self.render();
    });

    findActiveElement(self.menuItems);

    if (!isSomeElActive) {
      self.menuItems[0].active = true;
    }

    self.render();
  };

  self.events = {
    '#logoutButtonId click': onLogoutButtonClick,
  };
}
