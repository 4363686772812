import * as styles from './Header.module.css';
import IconCircle from '../iconCircle/IconCircle.jsx';

export default ({ o }) => {
  const logoutUrl = `${o.getParameter.light.casurl}/logout?service=${encodeURIComponent(o.app.getLogoutPageUrl())}`;

  return (
    <div class={`${styles.header} border-bottom`}>
      <div id={styles.overlay} onclick={o.closeEmployeeMenu} class="hidden"></div>
      <div id={styles.headerTop} class="border-bottom">
        <div class="content-container">
          <div class={styles.left}>
            <a class={styles.logo} href="#welcome/main" alt="Strona główna">
              <img src="images/pko-bez-napisu.jpg" alt="Logo PKO Platin light" />
              <div class={styles.text}>
                <div class={styles.upper}>Platin</div>
                <div class={styles.lower}>LIGHT</div>
              </div>
            </a>
          </div>
          <div class={styles.right}>
            <div id={styles.user} onclick={o.toggleEmployeeMenu} class="withArrow">
              <div class={styles.tooltipMenu}>
                <div class={styles.tooltipHeader}>Zmień jednostkę</div>
                <div class={styles.tooltipBody} style="height: 300px; overflow: auto;">
                  <ul id="unitListId" onclick={o.changeUserUnit}>
                    {o.userData.availableUnits.map((unit) => {
                      const selected = unit.statisticalUnitNumber === o.userData.selectedUnit.statisticalUnitNumber;
                      return (
                        <li class={selected ? styles.selectedUnit : ''} vx-unit-number={unit.statisticalUnitNumber}>
                          {unit.name}, <strong>{unit.statisticalUnitNumber}</strong>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <IconCircle>{o.userData.firstName[0] + o.userData.surname[0]}</IconCircle>
              <div class={styles.name}>
                {o.userData.firstName} {o.userData.surname}
                <br />
                <div class={styles.unit}>
                  {o.userData.selectedUnit && (
                    <>
                      {o.userData.selectedUnit.shortName},{' '}
                      <strong>{o.userData.selectedUnit.statisticalUnitNumber}</strong>
                    </>
                  )}
                  <br />
                  Role: {o.userData.selectedUnit.roles.join(', ')}
                </div>
              </div>
            </div>
            <a class={styles.logout} onClick={o.onLogout}>
              <img src="images/logout.svg" />
              Wyloguj
            </a>
          </div>
        </div>
      </div>
      <div id={styles.menuContainer} class="content-container">
        <div id="menu"></div>
      </div>
      {/*TODO: Co z tym zrobić? Submenu2 istnieje i są na to 2 kontrolki.*/}
      {/*<div id="submenu"></div>*/}
      {/*<div id="submenu2"></div>*/}
    </div>
  );
};
