import * as styles from './Footer.module.css';

export default ({ o }) => {
  return (
    <div class={styles.contentContainer}>
      Platin CRM © 2008-{new Date().getFullYear()} Departament Rozwoju Aplikacji Sprzedażowych i Wsparcia{' '}
      <a class="logTrace" href="#admin/Log?level=TRACE">
        PKO
      </a>{' '}
      Bank Polski
    </div>
  );
};
