export default function NavigationTemplate({ o }) {
  return (
    <div id="menu-left">
      <ul>
        {o.menuItems
          .filter((item) => item.available)
          .map((item) => {
            const activeClass = item.active ? 'current' : '';
            const href = o.app.navigation.currentEnvironment + item.href;
            return (
              <li class={activeClass}>
                <a href={href}>{item.label}</a>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
