/* eslint-disable */
import vx, { $I } from '@vx/framework';

import AlertController from './controllers/popup/AlertController';
import ConfirmController from './controllers/popup/ConfirmController';
import PopupController from './controllers/popup/PopupController';

import context from './context/index';
import * as navigation from './navigation';

import MessagesController from './controllers/message/MessagesController';
import ErrorController from './controllers/popup/ErrorController';

import { openPopup } from '@vx/components/modals';

export function popup(params = {}) {
  vx.load(PopupController, params);
}

export function showMessage(params = {}) {
  vx.load(MessagesController, params);
}

export function confirm(params) {
  openPopup(ConfirmController, {
    areaId: `xxConfirm${document.popupCounter || 0}`,
    controllerParams: params,
    header: params.header,
    hideCloseIcon: true,
  });
}

export function errorPopup(params) {
  openPopup(ErrorController, {
    areaId: 'app-error-popup',
    header: params?.header || 'Błąd!',
    width: params?.width || '675px',
    err: params.error,
  });
}

export function alert(params) {
  openPopup(AlertController, {
    areaId: `xxAlert${document.popupCounter || 0}`,
    controllerParams: params,
    header: params.header,
    hideCloseIcon: true,
  });
}

/**
 * Komponent umozliwia blokowanienie dostępu do elementów formularza
 * i pokazuje stan oczekiwania (spinner)
 */

export const wait = {
  show(areaId, message) {
    const area$ = $I(areaId);
    const width = area$.offsetWidth;
    const height = area$.offsetHeight;
    const loaderId = `${areaId}loader`;
    const loaderMessage = message ? message : 'Trwa pobieranie danych';
    const content = `<div class="vxContentLocker"></div><div class="vxContentLockerMessage">${loaderMessage}</div>`;
    const container$ = document.createElement('div');

    container$.id = loaderId;
    container$.className = 'vxContentLockerContainer';
    container$.style.width = `${width}px`;
    container$.style.height = `${height}px`;
    container$.style.marginTop = `-${height}px`;
    container$.innerHTML = content;
    area$.parentNode.appendChild(container$);
  },
  hide(areaId) {
    const loader$ = $I(`${areaId}loader`);

    if (loader$) {
      loader$.parentNode.removeChild(loader$);
    }
  },
};

export function to(promise) {
  return promise.catch((xhr) => {
    let error = {
      message: 'Wystąpił błąd',
      correlationId: 'brak',
      status: xhr?.status,
      url: xhr?.responseURL,
    };
    if (xhr?.response) {
      try {
        let parsedResponse = JSON.parse(xhr.response);
        if (typeof parsedResponse == 'object') {
          error.message = parsedResponse.message;
          error.correlationId = parsedResponse.correlationId;
        } else {
          error.message = `Wystąpił błąd: ${xhr.response}`;
        }
      } catch (e) {
        error.message = `Wystąpił błąd: ${xhr.response}`;
      }
    }

    errorPopup({ error });
    throw error;
  });
}

export { context };
export { navigation };
export { clearSession, getLogoutPageUrl } from './login';
